import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {CustomField} from '../types/CustomField';
import {SuccessResponse} from '../types/SuccessResponse';

const customField: ApiResource<CustomField> = {
  all: async () => {
    const reply = await axios.get<CustomField[]>(`/api/custom-field`);
    return reply.data;
  },
  get: async (id) => {
    const reply = await axios.get<CustomField>(`/api/custom-field/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<CustomField>(`/api/custom-field`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<CustomField>(`/api/custom-field/${data.id}`, data);
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/custom-field/${id}`);
    return reply.data;
  },
};

export const customFieldSchema = Yup.object({
  id: Yup.number(),
  client_id: Yup.number().optional(),
  name: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
});

export const generateCustomField = () => ({
  id: 0,
  name: '',
});

export default customField;

import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {ChatMessage} from '../types/ChatMessage';
import {SuccessResponse} from '../types/SuccessResponse';
import {Token} from '../types/Token';
import {User} from '../types/User';

export const userInputSchema = Yup.object({
  name: Yup.string(),
  email: Yup.string(),
  mobile_phone: Yup.string().nullable(),
  client_id: Yup.number(),
  roles: Yup.array(),
});

export const userRoleSchema = Yup.object({
  name: Yup.string(),
});

export const userSchema = Yup.object({
  id: Yup.number(),
  client_id: Yup.number().required(),
  patient_id: Yup.number(),
  name: Yup.string().required(),
  email: Yup.string().required(),
  password: Yup.string(),
});

type AdditionalUserResources = {
  me: () => Promise<User>;
  resetPassword: (currentPassword: string, newPassword: string) => Promise<SuccessResponse>;
  generateNewPassword: (id: number | string) => Promise<SuccessResponse>;

  switchToClient: (clientId: number | string) => Promise<SuccessResponse>;
  tokens: {
    list: () => Promise<Token[]>;
    issue: () => Promise<{token: string}>;
    revoke: (id: string) => Promise<SuccessResponse>;
    setName: (id: string, name: string) => Promise<SuccessResponse>;
  };
  unreadMentions: () => Promise<ChatMessage[]>;
  markMentionAsRead: (chat_message_id: string) => Promise<SuccessResponse>;
};

const user: ApiResource<User> & AdditionalUserResources = {
  all: async (search?: string) => {
    const reply = await axios.get<User[]>(`/api/user${search ? `?search=${search}` : ''}`);
    return reply.data;
  },
  get: async (id: string | number) => {
    const reply = await axios.get<User>(`/api/user/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<User>(`/api/user`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<User>(`/api/user/${data.id}`, data);
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/user/${id}`);
    return reply.data;
  },
  me: async () => {
    const reply = await axios.get<User>(`/api/user/me`);
    return reply.data;
  },
  resetPassword: async (currentPassword: string, newPassword: string) => {
    const reply = await axios.post<SuccessResponse>(`/api/user/reset-password`, {
      currentPassword,
      newPassword,
    });
    return reply.data;
  },
  generateNewPassword: async (id) => {
    const reply = await axios.get<SuccessResponse>(`/api/user/generate-new-password/${id}`);
    return reply.data;
  },
  switchToClient: async (clientId) => {
    const reply = await axios.get<SuccessResponse>(`/api/admin/switch-to-client/${clientId}`);
    return reply.data;
  },

  tokens: {
    list: async () => {
      const reply = await axios.get<Token[]>(`/api/user/token/list`);
      return reply.data;
    },
    issue: async () => {
      const reply = await axios.get<{token: string}>(`/api/user/token/issue`);
      return reply.data;
    },
    revoke: async (id: string) => {
      const reply = await axios.delete<SuccessResponse>(`/api/user/token/${id}`);
      return reply.data;
    },
    setName: async (id: string, name: string) => {
      const reply = await axios.post<SuccessResponse>(`/api/user/token/${id}`, {
        name,
      });
      return reply.data;
    },
  },
  unreadMentions: async () => {
    const reply = await axios.get<ChatMessage[]>(`/api/chat/user/unread-mentions`);
    return reply.data;
  },
  markMentionAsRead: async (chat_message_id: string) => {
    const reply = await axios.post<SuccessResponse>(`/api/chat/user/unread-mentions/mark-as-read`, {
      chat_message_id,
    });
    return reply.data;
  },
};

export default user;

import axios from 'axios';

type SendRequest = {
  recipient: string[];
  message: string;
  subject?: string;
};

const transmit = {
  email: async (props: SendRequest) => {
    const reply = await axios.post(`/api/transmit/email`, props);
    return reply.data;
  },
  sms: async (props: SendRequest) => {
    const reply = await axios.post(`/api/transmit/sms`, props);
    return reply.data;
  },
};

export default transmit;

import axios from 'axios';
import {Glum} from '../puddleglum';
import {ApiResource} from '../types/ApiResource';
import {SuccessResponse} from '../types/SuccessResponse';
import getGlobal from '../utils/getGlobal';

const regFormSubmission: ApiResource<Glum.Models.RegFormSubmission> = {
  all: async () => {
    const reply = await axios.get<Glum.Models.RegFormSubmission[]>(
      `/api/registration/reg-form-submission`,
    );
    return reply.data;
  },
  get: async (id: number) => {
    const reply = await axios.get<Glum.Models.RegFormSubmission>(
      `/api/registration/reg-form-submission/${id}`,
    );
    return reply.data;
  },
  create: async (data) => {
    const route = getGlobal<string>('updateUrl');
    if (!route) throw Error('No updateUrl found!');

    const reply = await axios.post<Glum.Models.RegFormSubmission>(route, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<Glum.Models.RegFormSubmission>(
      `/api/registration/reg-form-submission/${data.id}`,
      data,
    );
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(
      `/api/registration/reg-form-submission/${id}`,
    );
    return reply.data;
  },
};

export default regFormSubmission;

import axios from 'axios';
import {SuccessResponse} from '../types/SuccessResponse';
import {ChatSubscriber} from 'types/ChatSubscriber';

const chatSubscriber = {
  getUserSubscriptions: async () => {
    const reply = await axios.get<ChatSubscriber[]>(`/api/chat/user/subscriptions`);
    return reply.data;
  },
  getChannelSubscribers: async (chatChannelId: string) => {
    const reply = await axios.get<{
      internalSubscribers: ChatSubscriber[];
      externalSubscribers: ChatSubscriber[];
    }>(`/api/chat/channel/${chatChannelId}/subscribers`);
    return reply.data;
  },
  subscribeToChannel: async (chatChannelId: string | number, userId?: string | number) => {
    const reply = await axios.post<SuccessResponse>(
      `/api/chat/channel/${chatChannelId}/subscription${userId ? `?user_id=${userId}` : ''}`,
    );
    return reply.data;
  },
  unsubscribeFromChannel: async (chatChannelId: string) => {
    const reply = await axios.delete<SuccessResponse>(
      `/api/chat/channel/${chatChannelId}/subscription`,
    );
    return reply.data;
  },
  markAsRead: async (chatChannelId: string) => {
    const reply = await axios.post<SuccessResponse>(
      `/api/chat/channel/${chatChannelId}/mark-as-read`,
    );
    return reply.data;
  },
};

export default chatSubscriber;

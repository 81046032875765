import chatGif from './chatGif';
import communicationEvents from './communicationEvents';
import patient from './patient';
import regField from './regField';
import regForm from './regForm';
import regSection from './regSection';
import simulatedEvents from './simulatedEvents';
import testPanel from './testPanel';
import transmit from './transmit';
import verifyLogin from './verifyLogin';
import analytics from 'api/analytics';
import appointment from 'api/appointment';
import appointmentLocation from 'api/appointmentLocation';
import appointmentResource from 'api/appointmentResource';
import appointmentType from 'api/appointmentType';
import chatChannel from 'api/chatChannel';
import chatPatient from 'api/chatPatient';
import chatSubscriber from 'api/chatSubscriber';
import client from 'api/client';
import clientPhoneNumber from 'api/clientPhoneNumber';
import customField from 'api/customField';
import health from 'api/health';
import login from 'api/login';
import messageTemplate from 'api/messageTemplate';
import regFormSubmission from 'api/regFormSubmission';
import user from 'api/user';
import userSetting from 'api/userSetting';

const api = {
  login,
  verifyLogin,
  client,
  user,
  userSetting,
  health,
  analytics,
  patient,
  appointment,
  appointmentType,
  appointmentLocation,
  appointmentResource,
  messageTemplate,
  customField,
  testPanel,
  chatChannel,
  chatSubscriber,
  chatPatient,
  chatGif,
  clientPhoneNumber,
  simulatedEvents,
  communicationEvents,
  transmit,
  regForm,
  regSection,
  regField,
  regFormSubmission,
};

export default api;

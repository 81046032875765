import {format} from 'date-fns';

const makeQueryFromObject = (object: Object) =>
  Object.entries(object)
    .filter(([, value]) => value !== null && value !== undefined)
    .map(([key, value]) => {
      if (value instanceof Date) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(format(value, 'yyyy-MM-dd'))}`;
      }
      if (Array.isArray(value)) {
        return value
          .map((arrayItem) => `${encodeURIComponent(key)}[]=${encodeURIComponent(arrayItem)}`)
          .join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

export default makeQueryFromObject;

import axios from 'axios';
import {AppointmentStatus} from '../types/AppointmentStatus';
import {RecipientType} from '../types/CommunicationEvent';
import makeQueryFromObject from '../utils/makeQueryFromObject';

type AnalyticsGetRequests = {
  from?: Date;
  to?: Date;
  status?: AppointmentStatus[];
  recipient_type?: RecipientType;
};

export type TotalRate = {
  total: number;
  rate: number;
};

export type RatesByStatus = {
  date: string;
  total: number;
  total_seen: number;
} & Record<Partial<AppointmentStatus>, TotalRate>;

export type RatesByResponse = {
  date: string;
  total: number;
  total_seen: number;
} & Record<string, TotalRate>;

export type RatesByLocation = {
  location: string;
  total: number;
  total_seen: number;
} & Record<Partial<AppointmentStatus>, TotalRate>;

export type RatesByResource = {
  resource: string;
  total: number;
  total_seen: number;
} & Record<Partial<AppointmentStatus>, TotalRate>;

export type TotalAppointmentsByStatus = {
  date: string;
  total: number;
} & Record<Partial<AppointmentStatus>, number>;

const analytics = {
  totals: async (props: AnalyticsGetRequests) => {
    const reply = await axios.get<TotalAppointmentsByStatus[]>(
      `/api/analytics/totals?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
  rates: async (props: AnalyticsGetRequests) => {
    const reply = await axios.get<RatesByStatus[]>(
      `/api/analytics/rates?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
  responseRates: async (props: AnalyticsGetRequests) => {
    const reply = await axios.get<RatesByResponse[]>(
      `/api/analytics/response-rates?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
  ratesByLocation: async (props: AnalyticsGetRequests & {sort?: AppointmentStatus}) => {
    const reply = await axios.get<RatesByLocation[]>(
      `/api/analytics/rates-by-location?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
  ratesByResource: async (props: AnalyticsGetRequests & {sort?: AppointmentStatus}) => {
    const reply = await axios.get<RatesByResource[]>(
      `/api/analytics/rates-by-resource?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
  registrationRates: async (props: AnalyticsGetRequests) => {
    const reply = await axios.get<TotalRate[]>(
      `/api/analytics/registration-rates?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
};

export default analytics;

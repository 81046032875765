import axios from 'axios';
import * as Yup from 'yup';
import {Glum} from '../puddleglum';
import {ApiResource} from '../types/ApiResource';
import {SuccessResponse} from '../types/SuccessResponse';

export const regFormSchema = Yup.object({
  title: Yup.string().required().max(255, 'Title cannot exceed 255 characters.'),
  description: Yup.string().nullable(),
  sequence: Yup.number().required(),
});

const regForm: ApiResource<Glum.Models.RegForm> = {
  all: async () => {
    const reply = await axios.get<Glum.Models.RegForm[]>(`/api/registration/reg-form`);
    return reply.data;
  },
  get: async (id: number) => {
    const reply = await axios.get<Glum.Models.RegForm>(`/api/registration/reg-form/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<Glum.Models.RegForm>(`/api/registration/reg-form`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<Glum.Models.RegForm>(
      `/api/registration/reg-form/${data.id}`,
      data,
    );
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/registration/reg-form/${id}`);
    return reply.data;
  },
};

export default regForm;

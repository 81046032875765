import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {SuccessResponse} from '../types/SuccessResponse';

export const regFieldSchema = Yup.object({
  title: Yup.string().required().max(255, 'Title cannot exceed 255 characters.'),
  helper_text: Yup.string().required().max(255, 'Helper text cannot exceed 255 characters.'),
  is_required: Yup.boolean().default(false),
  config: Yup.object().nullable(),
});

const regField: ApiResource<App.Models.RegField> = {
  all: async () => {
    const reply = await axios.get<App.Models.RegField[]>(`/api/registration/reg-field`);
    return reply.data;
  },
  get: async (id: number) => {
    const reply = await axios.get<App.Models.RegField>(`/api/registration/reg-field/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<App.Models.RegField>(`/api/registration/reg-field`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<App.Models.RegField>(
      `/api/registration/reg-field/${data.id}`,
      data,
    );
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/registration/reg-field/${id}`);
    return reply.data;
  },
};

export default regField;

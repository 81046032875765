import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {SuccessResponse} from '../types/SuccessResponse';

export const regSectionSchema = Yup.object({
  title: Yup.string().required().max(255, 'Title cannot exceed 255 characters.'),
  description: Yup.string().nullable(),
  is_signature_required: Yup.boolean().default(false),
  is_signature_traced: Yup.boolean().default(false),
});

const regSection: ApiResource<App.Models.RegSection> = {
  all: async () => {
    const reply = await axios.get<App.Models.RegSection[]>(`/api/registration/reg-section`);
    return reply.data;
  },
  get: async (id: number) => {
    const reply = await axios.get<App.Models.RegSection>(`/api/registration/reg-section/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<App.Models.RegSection>(`/api/registration/reg-section`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<App.Models.RegSection>(
      `/api/registration/reg-section/${data.id}`,
      data,
    );
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/registration/reg-section/${id}`);
    return reply.data;
  },
};

export default regSection;

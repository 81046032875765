import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {AppointmentType} from '../types/AppointmentType';
import {SuccessResponse} from '../types/SuccessResponse';

export const appointmentTypeSchema = Yup.object({
  id: Yup.number(),
  client_id: Yup.number().optional().nullable(),
  category: Yup.string().max(255, 'Name cannot exceed 250 characters.').nullable(),
  name: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
  friendly_name: Yup.string().max(255, 'Name cannot exceed 250 characters.').optional().nullable(),
  phonetic_name: Yup.string().max(255, 'Name cannot exceed 250 characters.').optional().nullable(),
  instructions: Yup.string().optional().nullable(),
  call_template_id: Yup.number().optional().nullable(),
  sms_template_id: Yup.number().optional().nullable(),
  email_template_id: Yup.number().optional().nullable(),
});

const appointmentType: ApiResource<AppointmentType> = {
  all: async () => {
    const reply = await axios.get<AppointmentType[]>(`/api/appointment-type`);
    return reply.data;
  },
  get: async (id) => {
    const reply = await axios.get<AppointmentType>(`/api/appointment-type/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<AppointmentType>(`/api/appointment-type`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<AppointmentType>(`/api/appointment-type/${data.id}`, data);
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/appointment-type/${id}`);
    return reply.data;
  },
};

export default appointmentType;

import axios from 'axios';
import {ApiResource} from '../types/ApiResource';
import {Appointment} from '../types/Appointment';
import makeQueryFromObject from '../utils/makeQueryFromObject';

type AppointmentGetRequest = {
  from?: Date;
  to?: Date;
  status?: Array<
    'scheduled' | 'confirmed' | 'checked-in' | 'checked-out' | 'cancelled' | 'no-show'
  >;
  locations?: Array<string | number>;
  resources?: Array<string | number>;
  appointment_types?: Array<string | number>;
};

const appointment: Pick<ApiResource<Appointment>, 'all'> = {
  all: async (props: AppointmentGetRequest) => {
    const reply = await axios.get<Appointment[]>(`/api/appointment?${makeQueryFromObject(props)}`);
    return reply.data;
  },
};

export default appointment;

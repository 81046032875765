import axios from 'axios';
import {ApiResource} from '../types/ApiResource';

const patient: Pick<ApiResource<App.Models.Patient>, 'all'> = {
  all: async (search?: string) => {
    const reply = await axios.get<App.Models.Patient[]>(
      `/api/patient${search ? `?name=${search}` : ''}`,
    );
    return reply.data;
  },
};

export default patient;

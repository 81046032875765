import axios from 'axios';
import {VerifyResponse} from './verifyLogin';

export interface LoginRequest {
  email: string;
  password: string;
}

export type LoginResponse = {
  user: {
    id: number;
  };
  codeSentTo: string;
};

const login = async (data: LoginRequest): Promise<LoginResponse | VerifyResponse> => {
  const reply = await axios.post('/api/login', data);
  return reply.data;
};

export default login;

import axios from 'axios';
import {ApiResource} from '../types/ApiResource';
import {CommunicationEvent, RecipientType} from '../types/CommunicationEvent';
import makeQueryFromObject from '../utils/makeQueryFromObject';

export type EventRequest = {
  from?: Date;
  to?: Date;
  recipient_type?: Array<RecipientType>;
  date_column?: 'created_at' | 'response_at';
  response?: Array<
    | 'confirm'
    | 'cancel'
    | 'reschedule'
    | 'no answer'
    | 'no response'
    | 'voicemail'
    | 'tcpa blocked'
    | 'email limiter blocked'
    | 'unsubscribe blocked'
  >;
};

const simulatedEvents: Pick<ApiResource<CommunicationEvent>, 'all'> = {
  all: async (props: EventRequest) => {
    const reply = await axios.get<CommunicationEvent[]>(
      `/api/simulated-event?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
};

export default simulatedEvents;

import axios from 'axios';
import {ApiResource} from '../types/ApiResource';
import {CommunicationEvent} from '../types/CommunicationEvent';
import makeQueryFromObject from '../utils/makeQueryFromObject';

export type EventRequest = {
  from?: Date;
  to?: Date;
  recipient_type?: Array<'sms' | 'email' | 'call'>;
  response?: Array<
    | 'confirm'
    | 'cancel'
    | 'reschedule'
    | 'no answer'
    | 'no response'
    | 'voicemail'
    | 'tcpa blocked'
    | 'email limiter blocked'
    | 'unsubscribe blocked'
  >;
};

const communicationEvents: Pick<ApiResource<CommunicationEvent>, 'all'> = {
  all: async (props: EventRequest) => {
    const reply = await axios.get<CommunicationEvent[]>(
      `/api/communication-event?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
};

export default communicationEvents;

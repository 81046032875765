import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {AppointmentResource} from '../types/AppointmentResource';
import {SuccessResponse} from '../types/SuccessResponse';

export const appointmentResourceSchema = Yup.object({
  id: Yup.number(),
  client_id: Yup.number().nullable(),
  name: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
  friendly_name: Yup.string().max(255, 'Name cannot exceed 250 characters.').nullable(),
  phonetic_name: Yup.string().max(255, 'Name cannot exceed 250 characters.').nullable(),
  category: Yup.string().max(255, 'Category must be person or resource.').nullable(),
  instructions: Yup.string().nullable(),
  call_template_id: Yup.number().nullable(),
  sms_template_id: Yup.number().nullable(),
  email_template_id: Yup.number().nullable(),
});

export const generateAppointmentResource = (): AppointmentResource => ({
  id: 0,
  name: '',
  friendly_name: '',
  phonetic_name: '',
  category: '',
  instructions: '',
  call_template_id: 0,
  sms_template_id: 0,
  email_template_id: 0,
  call_template: null,
  sms_template: null,
  email_template: null,
  is_excluded_from_metrics: false,
  photo_url: '',
  is_call_enabled: false,
  is_email_enabled: false,
  is_sms_enabled: false,
});

const appointmentResource: ApiResource<AppointmentResource> = {
  all: async () => {
    const reply = await axios.get<AppointmentResource[]>(`/api/appointment-resource`);
    return reply.data;
  },
  get: async (id: number) => {
    const reply = await axios.get<AppointmentResource>(`/api/appointment-resource/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<AppointmentResource>(`/api/appointment-resource`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<AppointmentResource>(
      `/api/appointment-resource/${data.id}`,
      data,
    );
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/appointment-resource/${id}`);
    return reply.data;
  },
};

export default appointmentResource;

import axios from 'axios';
import {ApiResource} from 'types/ApiResource';
import {SuccessResponse} from 'types/SuccessResponse';
import {UserSetting} from 'types/UserSetting';
import makeQueryFromObject from 'utils/makeQueryFromObject';

const userSetting: ApiResource<UserSetting> = {
  all: async (props: {key: string}) => {
    const reply = await axios.get<UserSetting[]>(`/api/user-setting?${makeQueryFromObject(props)}`);
    return reply.data;
  },
  get: async (id) => {
    const reply = await axios.get<UserSetting>(`/api/user-setting/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<UserSetting>(`/api/user-setting`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<UserSetting>(`/api/user-setting/${data.id}`, data);
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/user-setting/${id}`);
    return reply.data;
  },
};

export default userSetting;

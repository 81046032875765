import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {ClientPhoneNumber} from '../types/Client';
import {SuccessResponse} from '../types/SuccessResponse';
import makeQueryFromObject from '../utils/makeQueryFromObject';

export const clientPhoneNumberSchema = Yup.object({
  id: Yup.number(),
  phone_number: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
});

const clientPhoneNumber: ApiResource<ClientPhoneNumber> = {
  all: async (props: {client_id?: string}) => {
    const reply = await axios.get<ClientPhoneNumber[]>(
      `/api/client-phone-number?${makeQueryFromObject(props)}`,
    );
    return reply.data;
  },
  get: async (id: number) => {
    const reply = await axios.get<ClientPhoneNumber>(`/api/client-phone-number/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<ClientPhoneNumber>(`/api/client-phone-number`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<ClientPhoneNumber>(`/api/client-phone-number/${data.id}`, data);
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/client-phone-number/${id}`);
    return reply.data;
  },
};

export default clientPhoneNumber;

import axios from 'axios';
import {Patient} from '../types/Patient';

const chatPatient = {
  getPatientInfo: async (patientId: number | string) => {
    const reply = await axios.get<Patient>(`/api/patient/${patientId}`);
    return reply.data;
  },
  unreadMessages: async (patientId: number | string) => {
    const reply = await axios.get<App.Models.ChatMessage[]>(
      `/api/chat/patient/${patientId}/unread`,
    );
    return reply.data;
  },
  alerts: async (patientId: number | string) => {
    const reply = await axios.get<Record<string, unknown>>(`/api/chat/patient/${patientId}/alerts`);
    return reply.data;
  },
  unreadCount: async (patientId: number | string) => {
    const reply = await axios.get<number>(`/api/chat/patient/${patientId}/unread-count`);
    return reply.data;
  },
};

export default chatPatient;

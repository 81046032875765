import axios from 'axios';

export type HealthStatus = {display: string; value: boolean};

const health = {
  all: async () => {
    const reply = await axios.get<HealthStatus[]>(`/api/health`);
    return reply.data;
  },
};

export default health;

import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {MessageTemplate} from '../types/MessageTemplate';
import {SuccessResponse} from '../types/SuccessResponse';

export const messageTemplateSchema = Yup.object({
  id: Yup.number(),
  client_id: Yup.number(),
  category: Yup.string(),
  description: Yup.string(),
  contents: Yup.string(),
  is_default: Yup.boolean(),
  message_use_case: Yup.string(),
});

const messageTemplate: ApiResource<MessageTemplate> = {
  all: async () => {
    const reply = await axios.get<MessageTemplate[]>(`/api/message-template`);
    return reply.data;
  },
  get: async (id) => {
    const reply = await axios.get<MessageTemplate>(`/api/message-template/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<MessageTemplate>(`/api/message-template`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<MessageTemplate>(`/api/message-template/${data.id}`, data);
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/message-template/${id}`);
    return reply.data;
  },
};

export default messageTemplate;

import axios from 'axios';

export interface TenorResponse {
  results?: ResultsEntity[] | null;
  next: string;
}

export interface ResultsEntity {
  id: string;
  title: string;
  media_formats: MediaFormats;
  created: number;
  content_description: string;
  itemurl: string;
  url: string;
  tags?: string[] | null;
  flags?: null[] | null;
  hasaudio: boolean;
}
export interface MediaFormats {
  tinywebm: Media;
  mediumgif: Media;
  tinygif: Media;
  gif: Media;
  mp4: Media;
  tinymp4: Media;
  gifpreview: Media;
  tinygifpreview: Media;
  nanomp4: Media;
  nanogif: Media;
  webm: Media;
  loopedmp4: Media;
  nanowebm: Media;
  nanogifpreview: Media;
}
export interface Media {
  url: string;
  duration: number;
  preview: string;
  dims?: number[] | null;
  size: number;
}

const chatGif = {
  search: async (term: string) => {
    const reply = await axios.get<TenorResponse>(`/api/chat/gif?search=${term}`);
    return reply.data;
  },
};

export default chatGif;

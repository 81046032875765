import axios from 'axios';
import {SuccessResponse} from '../types/SuccessResponse';

type TestPanelSendParams = {
  appointmentLocationId: number;
  appointmentResourceId: number;
  appointmentTypeId: number;
  emailAddress: string;
  phoneNumber: string;
  sendPhone: boolean;
  sendSms: boolean;
  sendEmail: boolean;
  sendSurvey: boolean;
  sendReminder: boolean;
  sendVisitSummary: boolean;
  apptDate: string;
  preferredLanguage: string;
};

const testPanel = {
  send: async (params: TestPanelSendParams) => {
    const reply = await axios.post<SuccessResponse>(`/api/test-panel/send`, params);
    return reply.data;
  },
};

export default testPanel;

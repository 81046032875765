import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {AppointmentLocation} from '../types/AppointmentLocation';
import {SuccessResponse} from '../types/SuccessResponse';

export const appointmentLocationSchema = Yup.object({
  id: Yup.number(),
  client_id: Yup.number(),
  name: Yup.string().required().max(255, 'Name cannot exceed 250 characters.').required(),
  friendly_name: Yup.string().nullable(),
  phonetic_name: Yup.string().nullable(),
  phone_number: Yup.string().required(),
  address1: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
  address2: Yup.string().optional().max(255, 'Name cannot exceed 250 characters.').nullable(),
  state: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
  city: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
  zip: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
  timezone: Yup.string().nullable(),
  instructions: Yup.string().nullable(),
  call_template_id: Yup.number().nullable(),
  sms_template_id: Yup.number().nullable(),
  email_template_id: Yup.number().nullable(),
});

export const generateAppointmentLocation = (): AppointmentLocation => ({
  id: 0,
  name: '',
  friendly_name: '',
  phonetic_name: '',
  address1: '',
  address2: '',
  state: '',
  city: '',
  zip: '',
  timezone: '',
  instructions: '',
  call_template_id: 0,
  sms_template_id: 0,
  email_template_id: 0,
  call_template: null,
  sms_template: null,
  email_template: null,
  client: undefined,
  phone_number: '',
});

const appointmentLocation: ApiResource<AppointmentLocation> = {
  all: async () => {
    const reply = await axios.get<AppointmentLocation[]>(`/api/appointment-location`);
    return reply.data;
  },
  get: async (id: number) => {
    const reply = await axios.get<AppointmentLocation>(`/api/appointment-location/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<AppointmentLocation>(`/api/appointment-location`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<AppointmentLocation>(
      `/api/appointment-location/${data.id}`,
      data,
    );
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/appointment-location/${id}`);
    return reply.data;
  },
};

export default appointmentLocation;
